
import abi from "./contract/GlobalPool.json";

import Buy from "./component/buy";
import Buy2 from "./component/buy2";
import Buy1 from "./component/buy1"
import Buy3 from "./component/buy3"
import Buy4 from "./component/buy4"
import Buy6 from "./component/buy6"
import Buy5 from "./component/buy5"
import Buy7 from "./component/buy7"
import Buy8 from "./component/buy8"

import "./copy.png";
import "./superbooster.png";


import { useState, useEffect } from "react";
import { ethers } from "ethers";

function App() {

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[refer,setrefer]=useState("None");
  const[dd,setDD]=useState("None");
const[referlink,setreflink]=useState("None");
const[p1,setp1]=useState("None");
const[p2,setp2]=useState("None");
const[p3,setp3]=useState("None");
const[p4,setp4]=useState("None");
const[p5,setp5]=useState("None");
const[p6,setp6]=useState("None");
const[p7,setp7]=useState("None");
const[p8,setp8]=useState("None");
const[pp1,setpp1]=useState("None");
const[pp2,setpp2]=useState("None");
const[pp3,setpp3]=useState("None");
const[pp4,setpp4]=useState("None");
const[pp5,setpp5]=useState("None");
const[pp6,setpp6]=useState("None");
const[pp7,setpp7]=useState("None");
const[pp8,setpp8]=useState("None");
const [tt,settt]=useState("None");
const[wid,setwid]=useState("None");
const[l1,setl1]=useState("None");
const[l2,setl2]=useState("None");
const[l3,setl3]=useState("None");
const[l4,setl4]=useState("None");
const[l5,setl5]=useState("None");
const[l6,setl6]=useState("None");
const[l7,setl7]=useState("None");
const[l8,setl8]=useState("None");
const[ll1,setll1]=useState("None");
const[ll2,setll2]=useState("None");
const[ll3,setll3]=useState("None");
const[ll4,setll4]=useState("None");
const[ll5,setll5]=useState("None");
const[ll6,setll6]=useState("None");
const[ll7,setll7]=useState("None");
const[ll8,setll8]=useState("None");

const[pack,setpackage]=useState("None");
const[t1,sett1]=useState("None");
const[b1,setb1]=useState("None");
const[b2,setb2]=useState("None");
const[b3,setb3]=useState("None");
const[b4,setb4]=useState("None");
const[b5,setb5]=useState("None");
const[b6,setb6]=useState("None");
const[b7,setb7]=useState("None");
const[b8,setb8]=useState("None");
const [tasks,setTasks] = useState([]);

         


useEffect(() => {
  const connectWallet = async () => {
    const contractAddress = "0x6bec44b1f53286c763cbba1024525b461ead6f21";
    const contractABI = abi.abi;
    const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    
    
    document.querySelector("#name").value=myParam;
if(myParam == null)
{
  document.querySelector("#name").value="0xA430903afFA0D5C6DA6d37d156f048fA384798FA";
}
    try {
      const { ethereum } = window;
      document.querySelector("#test").value = "Upgrade USDT 50";
      if (ethereum) {
        const account = await ethereum.request({
          method: "eth_requestAccounts",
         
        });
       
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });

        window.ethereum.on("accountsChanged", () => {
          
          window.location.reload();
        });
        const provider = new ethers.providers.Web3Provider(ethereum);
        try {
          const selectedNetwork = await provider.getNetwork();
        
          if (selectedNetwork.chainId !== 56) {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
            });
            console.log("You have switched to the Binance network");
          } else {
            console.log("You are already on the Binance network");
          }
        } catch (switchError) {
          // The network has not been added to MetaMask
          if (switchError.code === 4902) {
            console.log("Please add the Binance network to MetaMask");
          } else {
            console.log("Cannot switch to the network");
          }
        }
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          contractAddress,
          contractABI,
          signer 
        );
        setAccount(account);
        setState({ provider, signer, contract });

        
        document.querySelector("#us").value  = account[0];
        let alltasks = await contract.getMemos(account[0]);
        setTasks(alltasks);
        const b =Number(alltasks[2]);
        const refer = (alltasks[1]);
       setrefer(refer);
       document.querySelector("#hid").value  = (alltasks[1]);
       const direct =Number(alltasks[2]);
       setDirect(direct);

         const p1 = Number(alltasks[3]) ;
         setp1(p1);
         const p2 = Number(alltasks[4]) ;
         setp2(p2);
         const p3 = Number(alltasks[5]) ;
         setp3(p3);
         const p4 = Number(alltasks[6]) ;
         setp4(p4);
         const p5 = Number(alltasks[7]) ;
         setp5(p5);
         const p6 = Number(alltasks[8]) ;
         setp6(p6);
         const p7 = Number(alltasks[9]) ;
         setp7(p7);
         const p8 = Number(alltasks[10]) ;
         setp8(p8);

         const pp1 = 10 - Number(alltasks[3]) ;
         setpp1(pp1);
         const pp2 = 10 - Number(alltasks[4]) ;
         setpp2(pp2);
         const pp3 = 10 - Number(alltasks[5]) ;
         setpp3(pp3);
         const pp4 = 10 - Number(alltasks[6]) ;
         setpp4(pp4);
         const pp5 = 10 -  Number(alltasks[7]) ;
         setpp5(pp5);
         const pp6 = 10 -  Number(alltasks[8]) ;
         setpp6(pp6);
         const pp7 = 10 - Number(alltasks[9]) ;
         setpp7(pp7);
         const pp8 = 10 - Number(alltasks[10]) ;
         setpp8(pp8);

         const l1 = Number(alltasks[11]) * .1;
         setl1(l1);
         const l2 = Number(alltasks[12]) * .2;
         setl2(l2);
         const l3 = Number(alltasks[13]) * .3;
         setl3(l3);
         const l4 = Number(alltasks[14]) * .4;
         setl4(l4);
         const l5 = Number(alltasks[15]) *.5;
         setl5(l5);
         const l6 = Number(alltasks[16]) * 1;
         setl6(l6);
         const l7 = Number(alltasks[17]) * 2;
         setl7(l7);
         const l8 = Number(alltasks[18])  * 5;
         setl8(l8);

         const t1 = l1 + l2  + l3 + l4 + l5 + l6 + l7 + l8;
         sett1(t1);

         
         const b1 = Number(alltasks[19]) * 9;
         setb1(b1);
         const b2 = Number(alltasks[20]) * 18;
         setb2(b2);
         const b3 = Number(alltasks[21]) * 27;
         setb3(b3);
         const b4 = Number(alltasks[22]) * 36;
         setb4(b4);
         const b5 = Number(alltasks[23]) * 45;
         setb5(b5);
         const b6 = Number(alltasks[24]) * 90;
         setb6(b6);
         const b7 = Number(alltasks[25]) * 180;
         setb7(b7);
         const b8 = Number(alltasks[26]) * 450;
         setb8(b8);


         const ll1 = l1 + b1;
         setll1(ll1);
         const ll2 = l2 + b2;
         setll2(ll2);
         const ll3 = l3 + b3;
         setll3(ll3);
         const ll4 = l4 + b4;
         setll4(ll4);
         const ll5 = l5 + b5;
         setll5(ll5);
         const ll6 = l6 + b6;
         setll6(ll6);
         const ll7 = l7 + b7;
         setll7(ll7);
         const ll8 = l8 + b8;
         setll8(ll8);
       
        
         document.querySelector("#pp").value  = Number(alltasks[0]);
         
   
         setB(b);
         const referlink = 'https://superbooster.io/?ref='+ account;
         setreflink(referlink);
         
         const tt = pp1+pp2+pp3+pp4+pp5+pp6+pp7+pp8;
         settt(tt);


        
         document.querySelector("#link").value =referlink;
         const wid = ll1 + ll2 + ll3 + ll4 + ll5 + ll6 + ll7 + ll8;
          setwid(wid);
   //   document.querySelector("#test").value = "All Packages Purchased";
    
  
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
     
    
    
    connectWallet();
    
  }, []);

 
  // console.log(state);
        
       
  return (
   
<div style={{backgroundColor:"black"}}>
<input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="test"></input>

    <div class="page-container" >
 
    <table style={{color: "white",textAlign :"center",verticalAlign:"center", width :"100%",  backgroundColor:"white ",
}}>
            
      <tr><td>  
              
               <table width="100%">
               <tr>
               <td style={{  verticalAlign:"center", width :"100%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <img src ="superbooster.png" width="300px" height="200px" />
  </td></tr>
  <tr><td><br></br></td></tr>
     <tr>
               <td style={{  verticalAlign:"center", width :"100%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}> 
<br></br>
 <marquee>*Dear Leaders,
Focus on your work and do the participate in Superbooster.io. Every Users will be get Faster Boosters from here because 2×2 Matrix is very small in all Upgraded Packages. World's First Decentralized Gaming Platform "Supergame"
Launching Soon. This Ultimate Platform will be never end.
*</marquee>
  <br></br></td></tr>        
  
         </table>      
           
             
         <div class="clearfix"> </div>	
         <table width="100%"  >

<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  <h6>REFER LINK - {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
  navigator.clipboard.writeText(referlink);}} /></button></h6>
  <br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h6>MY SPONSOR - {refer}</h6>
  <br></br>
  <td style={{width:"3%" ,}}></td>
  </td>
  

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <Buy state={state} /> 
<br></br>
  </td>
  <td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
</table>


          <table width="100%">
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>TOTAL BOOSTER PURCHASED</h5>
<h6>  USDT {tt}  </h6><br></br> </td> 

<td style={{width:"3%" ,}}></td>
</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>

<td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
 
  <h5>MY DIRECT TEAM</h5>
<h6>   {direct}  </h6><br></br> </td>

<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>MY TOTAL INCOME</h5>
<h6>  USDT {wid}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>MY TOTAL LEVEL INCOME</h5>
<h6>  USDT {t1}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>BOOSTER-I AVAILABLE</h5>
<h6>  USDT {p1}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>


<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-I</h5>
<h6>   <Buy1 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>BOOSTER-II AVAILABLE</h5>
<h6>  USDT {p2}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr><tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-II</h5>
<h6>   <Buy2 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>BOOSTER-III AVAILABLE</h5>
<h6>  USDT {p3}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr><tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-III</h5>
<h6>   <Buy3 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>BOOSTER-IV AVAILABLE</h5>
<h6>  USDT {p4}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>
</tr><tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-IV</h5>
<h6>   <Buy4 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>

<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>BOOSTER-V AVAILABLE</h5>
<h6>  USDT {p5}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-V</h5>
<h6>   <Buy5 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <h5>BOOSTER-VI AVAILABLE</h5>
<h6>  USDT {p6}  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr><tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-VI</h5>
<h6>   <Buy6 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-VII</h5>
<h6>   <Buy7 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
<td  style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",}}>
  
  <h5>PURCHASE BOOSTER-VIII</h5>
<h6>   <Buy8 state={state} />  </h6><br></br> </td>
<td style={{width:"3%" ,}}></td>

</tr>
<tr><td></td><td><br></br></td><td></td></tr>
</table>

           
                          
    
    <div class="clearfix"></div>
  
  <table style={{width:"100%" ,}}><tr><td  style={{color: "#191970" , textAlign :"center",verticalAlign:"center", }}>
	 © 2024 SUPERBOOSTER.IO . All Rights Reserved  </td></tr></table>
   </td></tr></table>

</div>
</div>


       

           
                   
	




     

  );
}

export default App;
